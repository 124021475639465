<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/Incident-min.jpg"
              width="698"
              height="446"
              alt="incident investigation"
              title="Incident"
              class="img-responsive wp-image-12804"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Incident Investigation" size="large" />
        <Paragraph
          ><p>
            Unfortunately, even companies with the best safety program
            experience accidents and must deal with an investigation into the
            root causes. Some accidents leave very clear trails of evidence to
            their root causes. Others leave very few clues due to destruction or
            loss of evidence. When this occurs only a very detailed and careful
            reconstruction of the situation can result in identification of the
            root causes and the “most credible scenario.”
          </p>
          <p>
            SMS personnel have extensive experience in participating with and
            leading incident investigations in the explosives, chemical, and
            manufacturing industries. We specialize in root cause analysis,
            reconstruction of events, and development of incident scenarios. SMS
            personnel are very versatile and can be quickly mobilized to your
            facility should the need arise.
          </p>
          <p>
            Incident investigation is a component of both Process Safety
            Management (PSM) and Risk Management Programs (RMP). Our incident
            investigation services may be used independently or in conjunction
            with our process hazards analysis, explosive characterization,
            facility siting, and other analysis services. Additionally, SMS
            offers training courses on the fundamental techniques used in
            explosives sensitivity testing and hazards analysis.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Incident Investigation",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS specializes in root cause analysis, reconstruction of events, and development of incident scenarios to effectively analyze any incident."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
